import { withUnpublishedPreview } from 'gatsby-source-prismic';
import * as React from 'react';

import HomePage from '../templates/HomePage';

const NotFoundPage = () => (
  <div>
    <h1>404</h1>
    <h2>Page not found!</h2>
  </div>
);

export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    layout: HomePage,
  },
});
